import React from "react";
import "../../style/agenda/agenda-day.scss";

const AgendaDayTwo = props => (
    <div className="agenda-day">
        <h1>Drugi dan - Game development</h1>
        <h2>15:00</h2>
        <p>Otvaranje drugog dana</p>
        <h2>15:15</h2>
        <p>Igrom do uspeha, Sozap</p>
        <h2>16:00</h2>
        <p>Pauza</p>
        <h2>16:15</h2>
        <p>Ingsoftware</p>
        <h2>17:00</h2>
        <p>Networking</p>
        <h2>17:30</h2>
        <p>TBA</p>
        <h2>18:15</h2>
        <p>Pauza</p>
        <h2>18:30</h2>
        <p>Bonus predavanje: Kako pokrenuti uspešan web dizajn biznis, Jovan Ivković, Freelance Web Designer & Developer</p>
        <h2>19:15</h2>
        <p>Svečano zatvaranje događaja</p>
    </div>
);

export default AgendaDayTwo;