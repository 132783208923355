import React from "react"
import {Route,Link,Switch,BrowserRouter} from "react-router-dom";
import "../style/index.scss"
import SponsorPage from "./sponsors"
import HomePage from "./Home"

class App extends React.Component {

    render() {
        return (
            <BrowserRouter>
                <div style={{ height: "100%" }}>
                    <Switch>
                        <Route exact path="/" component={HomePage}></Route>
                        <Route exact path="/sponsors" component={SponsorPage}></Route>
                    </Switch>
               </div>
            </BrowserRouter>

        );
    }
}

export default App;
