import React from "react"
import "../../style/lecturers/lecturers-list.scss"
import LecturersCard from "./lecturers-card"

import predavac1 from "../../assets/predavaci/predavac1.PNG"
import predavac2 from "../../assets/predavaci/predavac2.jpg"
import predavac3 from "../../assets/predavaci/predavac3.jfif"
// import predavac4 from "../../assets/predavaci/predavac4.jpg"
import predavac from "../../assets/predavaci/predavac.jpg"
import predavac8 from "../../assets/predavaci/predavac8.jpg"

class LecturersList extends React.Component {
    render() {
        return (
            <div className="lecturers-list">
                <div className="lecturers-list-row">
                    <LecturersCard slika={predavac1} ime="Stefan Stamenović" firma="Diligent">Stefan je vrsni strucnjak sa iskustvom u radu na više polja razvoja i projektovanja softvera. Trenutno u Diligentu radi na poziciji lead software inzenjera izmedju ostalog zaduženog za tehnološki napredak. Za sebe kaže da je timski igrac i  veliki zaljubljenik u arhitekturu softvera.</LecturersCard>
                    <LecturersCard slika={predavac2} ime="Ivan Petković" firma="Nignite">Katedra za računarstvo, Elektronski fakultet, Univerzitet u Nišu Oblasti istraživanja i rada: Web tehnologije, objektno orijentisano programiranje, arhitektura softverska, domenski vođeno projektovanje. Preko 20 godina rada u softverskoj industriji za inostrane klijente.</LecturersCard>
                </div>
                <div className="lecturers-list-row">
                    <LecturersCard slika={predavac3} ime="Aleksandar Mijalković" firma="RCMT IT Europe"> Experienced engineer with a demonstrated history of working in the computer software and electronic industry. Skilled in electronics: protel99, smps, optics, low level networks, component level repair, testing and root cause analysis in electronics. Skilled in Java, Android, TSQL, C# and C++ Development. Strong engineering professional with a Master's degree focused in Telecomunication & Programming from Faculty of Electrical Engineering & Computing.</LecturersCard>
                    <LecturersCard slika={predavac8} ime="Jovan Ivković" firma="Freelance Web Designer & Developer">Ja sam internet preduzetnik koji vec 6 godina pruža usluge web dizajna i developmenta klijentima iz preko 30 zemalja sveta. Saradivao sam sa firmama poput najvece svetske kripto menjacnice Coinbase i fudbalskog kluba Chelsea. Trenutno saradujem sa poznatom marketinškom agencijom iz Londona, kao i sa mnogobrojnim americkim firmama iz oblasti arhitekture i umetnosti. Ocekujem da do kraja godine moja firma broji 10 clanova.</LecturersCard>
                </div>
                <div className="lecturers-list-row">
                    <LecturersCard slika={predavac} ime="TBA" firma="TBA">TBA</LecturersCard>
                    <LecturersCard slika={predavac} ime="TBA" firma="TBA">TBA</LecturersCard>
                </div>
                <div className="lecturers-list-row">
                    <LecturersCard slika={predavac} ime="TBA" firma="TBA">TBA</LecturersCard>
                    <LecturersCard slika={predavac} ime="TBA" firma="TBA">TBA</LecturersCard>
                      </div>
            </div>
        )
    }
}

export default LecturersList;