import React from 'react';
import Slider from 'infinite-react-carousel';
import "../../style/sponsors/slider.scss";

import ntpLogo from "../../assets/sponzori/ntp_logo.png";
import elfakLogo from "../../assets/sponzori/elfak_logo.png";
import sicefLogo from "../../assets/sponzori/sicef_logo.png";
import rcmtLogo from "../../assets/sponzori/rcmt_logo.png";
import diligentLogo from "../../assets/sponzori/diligent_logo.png"
import BCLogo from "../../assets/sponzori/BC_logo.png"
import IngLogo from "../../assets/sponzori/ing_logo.png"
import MHGLogo from "../../assets/sponzori/MHG_logo.png"
import nlhLogo from "../../assets/sponzori/nlh_logo.png"
import productHiveLogo from "../../assets/sponzori/Product_Hive.png"

class SimpleSliderP extends React.Component {

    render() {
        const settings = {
            autoplay: true,
            autoplaySpeed: 500,
            centerPadding: 10,
            duration: 1500,
            overScan: 3,
            slidesToShow: 3,
            wheel: true,
            arrows: false
        };

        return (
            <div className="slider">
                <Slider {...settings}>
                    <div className="slika-container">
                        <a href="https://ntp.rs/en/">
                            <img className="slika" alt="sponzor " src={ntpLogo}></img></a>
                    </div >
                    <div className="slika-container">
                        <a href="https://www.elfak.ni.ac.rs">
                            <img className="slika" alt="sponzor " src={elfakLogo}></img></a>
                    </div>
                    <div className="slika-container">
                        <a href="http://www.sicef.info">
                            <img className="slika" alt="sponzor " src={sicefLogo}></img></a>
                    </div>
                    <div className="https://nolimithub.com/">
                        <a href="https://www.rcmtiteurope.com">
                            <img className="slika" alt="sponzor " src={nlhLogo}></img></a>
                    </div>
                </Slider>
            </div>
        );
    }
}

export default SimpleSliderP;