import React from "react";
import "../../style/agenda/agenda-day.scss";

const AgendaDayOne = props => (
    <div className="agenda-day">
        <h1>Prvi dan - Web development</h1>
        <h2>15:00</h2>
        <p>Svečano otvaranje</p>
        <h2>15:15</h2>
        <p>Blockchain - investicija znanja za budućnost, Stefan Stamenović, Lead Software Engineer @ Diligent</p>
        <h2>16:00</h2>
        <p>Pauza</p>
        <h2>16:15</h2>
        <p>Ivan Petković, CEO @ Nignite</p>
        <h2>17:00</h2>
        <p>Networking</p>
        <h2>17:30</h2>
        <p>Better Collective</p>
        <h2>18:15</h2>
        <p>Pauza</p>
        <h2>18:30</h2>
        <p>Automation Testing and ci/cd, Aleksandar Mijalković, Senior QA @ RCMT IT Europe</p>
        <h2>19:15</h2>
        <p>Zatvaranje prvog dana</p>
    </div>
);

export default AgendaDayOne;