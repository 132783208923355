import React, { Component } from 'react';
import "../../style/sponsors-page/sponsors-page-list.scss";
import SponsorsPageCard from "./sponsors-page-card";

import bronzeImg from "../../assets/bronze.png";
import platinumImg from "../../assets/platinum.png";
import goldImg from "../../assets/gold.png";
import silverImg from "../../assets/silver.png"
import partneriImg from "../../assets/partneri.png";

import ntpLogo from "../../assets/sponzori/ntp_logo.png";
import elfakLogo from "../../assets/sponzori/elfak_logo.png";
import sicefLogo from "../../assets/sponzori/sicef_logo.png";
import rcmtLogo from "../../assets/sponzori/rcmt_logo.png";
import diligentLogo from "../../assets/sponzori/diligent_logo.png"
import BCLogo from "../../assets/sponzori/BC_logo.png"
import IngLogo from "../../assets/sponzori/ing_logo.png"
import MHGLogo from "../../assets/sponzori/MHG_logo.png"
import nlhLogo from "../../assets/sponzori/nlh_logo.png"
import productHiveLogo from "../../assets/sponzori/Product_Hive.png"
import MDCLogo from "../../assets/sponzori/MDC_logo.png"

class SponsorsPageList extends Component {
    render() {
        return (
            <div className="sponsors-page-list">
                <p className="naslov">Platinum</p>
                <div className="drzacdiva">
                    <SponsorsPageCard
                        slika1={platinumImg}
                        slika2={diligentLogo}
                        tekst={"Diligent is one of the fastest-growing companies from Southern Serbia adding more than 40 software engineers during the last year, easily overcoming challenges that a year of pandemic brought to the entire world. Our main area of expertise lies in solutions for the financial industry where we have more than a decade of experience in helping fintech startups and financial institutions to manage their risks, expand their business and stay ahead of the curve by developing financial software solutions and integrating them with third-party systems while ensuring stable operation of financial systems"

                        + "Our Vision is to be a leading software solutions company for a variety of industries. We know that customer’s growth is our growth, so we commit ourselves to help the customers in achieving their business goals. We want to be known as a reliable, innovative, and top-quality software service provider in the IT industry."
                        
        + "Our Mission is to enhance the business growth of our customers with creative design, development and to deliver market-defining high-quality solutions that create value and reliable competitive advantage to customers around the globe."}
                    ></SponsorsPageCard>
                    {/*<SponsorsPageCard
                        slika1={require("../../assets/platinum.png")}
                        slika2={require("../../assets/sponzori/sponzor1.png")}
                        tekst={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur"}
                    ></SponsorsPageCard>*/}
                </div>
                <p className="naslov">Gold</p>
                <div className="drzacdiva">
                    <SponsorsPageCard
                        slika1={goldImg}
                        slika2={BCLogo}
                        tekst={"Better Collective d.o.o. Niš je deo danske IT kompanije Better Collective, koja je lider u kreiranju edukativnih platformi za iGaming industriju. Zahvaljujuci svojim platformama i alatima Better Collective povezuje korisnike sa iGaming operaterima koji odgovaraju njihovim potrebama. "
                        + "Better Collective su 2004. godine osnovali Jasper Segor i Kristian Kirk Rasmusen u Danskoj, kao start-up kompaniju. "
                        + "Danas Better Collective ima više od 450 zaposlenih u 12 kancelarija širom sveta - Kopenhagen, Pariz, Bec, Stokholm, Solun, Lod, Krakov, Stoke-on-Trent, London, Nešvil, Fort Loderdejl i Niš. "
                        + "Kada smo 2016. godine otvorili kancelariju u Nišu, nismo mogli ni da zamislimo da cemo postici sve što smo postigli danas. Niš je bio prvi grad u kome smo otvorili svoje kancelarije van sedišta kompanije. Sada u Nišu imamo više od 160 zaposlenih. "
                        + "Stalno smo u potrazi za novim talentima razlicitih obrazovnih profila koji bi se pridružili našem internacionalnom timu. Naši zaposleni imaju privatno zdravstveno osiguranje, 100% placeno bolovanje, dodatne dane odmora, mogucnost napredovanja i pohadanja konferencija i edukacija, fleksibilno radno vreme, rucak u kancelariji, timske aktivnosti i razonodu u kancelariji."}
                    ></SponsorsPageCard>
                    <SponsorsPageCard
                        slika1={goldImg}
                        slika2={productHiveLogo}
                        tekst={"Product Hive is a company that builds kickass digital products."
                        + "We use the latest high-end technologies and our cross-domain experience of tech and business to help our customers grow their business and develop custom web and mobile solutions."}
                    ></SponsorsPageCard>
                </div>
                <p className="naslov">Silver</p>
                <div className="drzacdiva">
                    <SponsorsPageCard
                        slika1={silverImg}
                        slika2={IngLogo}
                        tekst={"Ingsoftware je kompanija koja se bavi projektovanjem i razvojem softverskih rešenja. Osnovani smo 2009. godine u Nišu, gde je i sedište kompanije, a imamo predstavništva u Severnoj Americi, Africi i više evropskih država. U proteklih 11 godina radili smo sa više od 50 klijenata na preko 150 raznovrsnih projekata. Saradivali smo sa kompanijama razlicitih velicina, od malih startap projekata u ranim fazama razvoja, do najvecih svetskih korporacija."}
                    ></SponsorsPageCard>
                    <SponsorsPageCard
                        slika1={silverImg}
                        slika2={rcmtLogo}
                        tekst={"Kompanija RCMT IT Europe nastala je 2018. godine kao evropsko IT predstavništvo američke korporacije RCMT. U sklopu naše kompanije nalaze se 4 sektora koji kroz međusobnu saradnju, ali i individualne projekte, postižu zavidne rezultate u smislu produktivnosti, efikasnosti i inovacije poslovnih procesa interno i eksterno. Sektor razvoja (Reaserch and Develop) radi na kreiranju kako klasičnih biznis softvera, tako i modernih multiplatformskih aplikacija."
                        + "Inzinjering sektor (Enginering/ Cybersecurity) kroz najmodernije alate vrši celodnevni monitoring i analizu klijentskih mreža, sprečavajući potencijalne pretnje po njihovu sigurnost."
                        + "Finansijsko-analitički sektor (Payroll and Billing) koristeći najmoderniji softver iz domena knjigovođstva i računovođstva bavi se obračunom zarada za strane klijente, prema aktuelnim zakonima države u kojoj klijenti posluju."
                        + "Sektor digitalnog marketinga (Digital) svakodnevno poboljšava onlajn prisustvo, sopstveno i svojih klijenata, kroz razne aktivnosti na društvenim mrežama, Gugl Ads kampanje, SEO i content marketing."
                        + "Sazdana od preko 80 stručnjaka iz sve 4 oblasti, uz vođstvo kreativnog i odvažnog menadžmenta, kompanija RCMT IT Europe je za tri godine, u niškoj IT zajednici ali i na međunarodnoj sceni, postavila zdrave temelje poslovanja.Velike cybersecurity i engineering kompanije iz Sjedinjenih Američkih Država, Kanade i nekoliko zemalja članica Evropske unije su samo neki od naših klijenata."}
                    ></SponsorsPageCard>
                </div>
                <p className="naslov">Bronze</p>
                <div className="drzacdiva">
                    <SponsorsPageCard
                        slika1={bronzeImg}
                        slika2={MHGLogo}
                        tekst={"Mad Head Games studio poceo je kao lokalni indie game developer iz Beograda i Novog Sada osnovan od strane pasioniranih, marljivih i posvecenih zaljubljenika u video igre koji su verovali u moc video igara da u svaciji život unesu više zabave i imaginacije. Njihova prica pocinje 2011. kada je studio osnovan od strane iskusnih developera i prijatelja - pionira tadašnje domace game dev scene.  "   
                        + "U godinama koje su dolazile, tim je rastao okupljen oko zajednicke vizije, a to je stvaranje narativnih, atmosfericnih i zabavnih igrackih iskustava. Prvi veliki uspesi došli su sa HOPA naslovima objavljenim od strane globalnog izdavaca casual hitova Big Fish Games. Danas, nekoliko godina kasnije, studio radi na nekolicini projekata za core tržište razvijanih u Unreal Engine-u. " 
                        + "U novembru 2020 Mad Head Games studio postaje deo velike evropske gaming porodice - Embracer Grupe, kada joj se pridružuje kao novi clan koji ce nastaviti da razvija sopstvene core projekte. Ovaj srpski game dev pionir nastavice da primenjuje svoje iskustvom steceno znanje i ekspertizu sa ciljem da srpski core game development pozicionira na svetsku mapu AA+ gejminga."}
                    ></SponsorsPageCard>
                    <SponsorsPageCard
                        slika1={bronzeImg}
                        slika2={MDCLogo}
                        tekst={"Mad Duck Code kompanija iz Novog Sada je grupa entuzijasta koji vole da rade ono što najbolje znaju - stvaraju digitalne proizvode."
                        +"Osnovana je 2017. godine, nastala kao rezultat dugogodišnjeg prijateljstva koje je rezultiralo partnerstvom. Ova dva partnera inspiraciju su našli u zajedničkoj ljubavi prema tehnologiji."
                        +"Vođeni iskustvom, prikupli smo sve najbolje prakse u svojim oblastima i koristili ih za stvaranje usluga koje menjaju život svih naših klijenata."
                        +"Filozofija našeg tima jeste da „Kupci nikada neće voleti kompaniju dok je zaposleni ne zavole“, pa prema tome naši programeri i menadžeri uživaju u odličnoj radnoj atmosferi, gde podstičemo kreativnost i delimo znanje međusobno, sa puno smeha svakoga dana. Upravo to radno okruženje je ono što naš tim čini posebnim."}
                    ></SponsorsPageCard>
                </div>
                {/*<p className="naslov">Robna podrška</p>
                <div className="drzacdiva">
                    <SponsorsPageCard
                        slika1={require("../../assets/robni.png")}
                        slika2={require("../../assets/sponzori/sponzor1.png")}
                        tekst={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur"}
                    ></SponsorsPageCard>
                </div>*/}
                <p className="naslov">Podrška</p>
                <div className="drzacdiva">
                    <SponsorsPageCard
                        slika1={partneriImg}
                        slika2={elfakLogo}
                        tekst={"Otpočeo sa radom 1. oktobra 1960. godine kao Elektronski odsek Tehničkog fakulteta u Nišu, koji je tada bio u sastavu Univerziteta u Beogradu.U početku je nastava izvođena u okviru „Zavoda RR“ (kasnije Elektronska industrija) da bi od 1964. godine otpočela nastava u zgradi Tehničkog fakulteta. Godine 1965, formira se Univerzitet u Nišu i Tehnički fakultet postaje sastavni deo Niškog univerziteta. Od 1968. godine Elektronski odsek prerasta u fakultet i počinje da radi samostalno kao Elektronski fakultet u Nišu."}
                    ></SponsorsPageCard>
                    <SponsorsPageCard
                        slika1={partneriImg}
                        slika2={ntpLogo}
                        tekst={"Naucno-tehnološki park Niš (NTP Niš) je neprofitna organizacija koja u bliskoj saradnji sa Univerzitetom u Nišu i akademskom zajednicom obezbeduje potrebnu infrastrukturnu i strucnu podršku inovativnim preduzecima u razvoju i ostvarivanju poslovnih uspeha na tržištu, posebno u oblasti visokih tehnologija. Osnovan je 2020. godine u partnerstvu Vlade Republike Srbije, Univerziteta u Nišu i Grada Niša."
                        + "Dinamicna zajednica akademskih institucija, visokotehnoloških kompanija i startap kompanija okupljena na jednom ovakvom prostoru od 14000m2 predstavlja jedinstveno okruženje idealno za rad, razmenu znanja i iskustava, kao i za poslovnu saradnju i komercijalizaciju naucnih istraživanja."
                        + "NTP Niš je od oktobra postao dom 18 startap i 13 tehnološko-razvojnih kompanija clanova, pa se sada ponosimo cinjenicom da se uz našu podršku intenzivno radi na više od 30 inovativnih projekata. Blizu 500 ljudi iz ovog regionalnog centra razvija jedinstvene proizvode i usluge iz oblasti naprednih tehnologija, ukljucujuci gejming, blokcejn, veštacku inteligenciju, mašinsko ucenje, Internet of Things, cloud tehnologiju, itd."}
                    ></SponsorsPageCard>
                    <SponsorsPageCard
                        slika1={partneriImg}
                        slika2={nlhLogo}
                        tekst={"No Limit Hub is a non-profit organization that gathers together the largest community of developers in Niš. No Limit Hub empowers the development of entrepreneurship and creates space for knowledge exchange in the IT area by organizing meetups and two big conferences - KickassSaas and CODEstantine. "}
                    ></SponsorsPageCard>
                </div>
            </div>
        );
    }
}

export default SponsorsPageList;